<template>
  <div class="observeInfo">
    <div class="observeInfoBox common-main">
      <div class="headTitle">
        <h2 class="title_zh">
          {{data.title}}
          <div class="fenxBox">
            <img class="fenx" src="../assets/images/icon_fenx.png" alt />
            <p>分享</p>
            <share/>
          </div>
        </h2>
        <h2 class="title_en">{{data.sub_title}}</h2>
      </div>
      <div class="projectInfoContent" v-html="data.content"></div>
      <div class="article mh134 b-t-1 p-t-34 p-b-10">
        <div class="view flex-row">
          <div class="m-r-15 fz-14 c000">相关文章</div>
          <div>
            <div v-if="articlelist.length > 0" :class="showMore ? 'hided':''" >
              <div class="fz-14 c000 pointer hore m-b-12" @click="open(item)" v-for="(item,index) in articlelist" :key="index" >{{ item.title }}</div>
            </div>
            <div v-else class="fz-14 c000">暂无</div>
            <div v-if="articlelist.length > 4" @click="showMore = !showMore" class=" text-center pointer m-t-10 fz-14">
              
              <span v-if="showMore" class="fz-14 c000">{{ $t("m.more") }}</span>
              <span v-else class="fz-14 c000">{{ $t("m.close") }}</span>
              <img :style="showMore ? 'padding-bottom:4px;':'transform: rotate(180deg);'" style="height: 22px;" src="@/assets/images/more.png" class="w18 h18 inline-block"/>
    
            </div>
            
          </div>
          
          
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import share from "@/components/share.vue";
export default {
  data() {
    return {
      data: "",
      showMore: true,
      articlelist: [],
    };
  },

  components: {
    share
  },
  created() {
    this.getData();
    this.getArticles()
  },
  updated() {
   
  },
  methods: {
    open(url) {
      window.open(url.link)
    },
    getArticles() {
      this.axios
        .get(
          this.GLOBAL.serverSrc + "/wc/visionRelate?id=" + this.$route.params.id
        ) // 真实环境中，后端会根据参数group返回新的图片数组，这里我用一个惊呆json文件模拟
        .then((res) => {
          if (res.data.code == 1) {
            this.articlelist = res.data.data
          }
        })
    },
    getData() {
      this.axios
        .get(
          this.GLOBAL.serverSrc + "/wc/newsDetail?id=" + this.$route.params.id
        ) // 真实环境中，后端会根据参数group返回新的图片数组，这里我用一个惊呆json文件模拟
        .then((res) => {
          console.log(res);
          if (res.data.code == 1) {
            this.data = res.data.data;
            document.body.scrollTop = document.documentElement.scrollTop = 0;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.observeInfo {
  width: 100%;
  height: 100%;
}
.observeInfoBox {
  min-height: 100%;
  padding: 110px 150px 0 150px;
  width: 100%;
  .headTitle {
    border-bottom: 1px solid #ccc;
    .title_zh {
      color: #000;
      font-size: 34px;
      cursor: pointer;
      font-weight: 800;
      .fenxBox {
        float: right;
        cursor: pointer;
        .fenx {
          width: 20px;
          height: 20px;
        }
        p {
          font-size: 12px;
          color: #808080;
        }
      }
    }
  }

  .title_en {
    cursor: pointer;
    font-weight: 600;
    margin-bottom: 60px;
    font-size: 24px;
  }
}
</style>